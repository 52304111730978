<template>
  <div class="page-content settings">
    <progress-indicator v-if="!loaded">
      Loading...
    </progress-indicator>
    <div v-else class="worldwide-mode">
      <div class="worldwide-mode-checkbox">
        <span>Choose your tracking keywords mode:</span>
        <div class="worldwide-mode-checkbox-container">
          <table-checkbox
              :checked="worldwideTrackingModeEnable"
              @input-checked="worldwideTrackingModeEnable = !worldwideTrackingModeEnable"
          >
            Enable Worldwide Tracking Mode
          </table-checkbox>
        </div>
        <div class="worldwide-mode-checkbox-caution">
          ⚠️<strong>Caution:</strong> Enabling this may increase tracking keyword limits.
        </div>
      </div>
      <div class="worldwide-mode-explanation">
        <div class="worldwide-mode-explanation-item">
          <div class="worldwide-mode-explanation-title">
            ⚪️ When Worldwide Tracking Mode is Disabled:
          </div>
          <div class="worldwide-mode-explanation-text">
            Adding a keyword to a specific country will track it only in that country.
            <span class="cursive" style="margin-top: 15px">
              For example, if you add a Spanish keyword to Spain, it will be tracked only in Spain. To track it in other countries where the keyword is popular, you would need to add it manually to each country.
            </span>
          </div>
        </div>
        <div class="worldwide-mode-explanation-item">
          <div class="worldwide-mode-explanation-title">
            🟢 When Worldwide Tracking Mode is Enabled:
          </div>
          <div class="worldwide-mode-explanation-text">
            Adding a keyword to any country will also track it in other countries:
            <ul>
              <li>📈 Where the keyword is popular</li>
              <li>🌐 Where the language of the keyword is commonly used (If the keyword language is incorrectly defined, you can change it to avoid tracking in incorrect countries.)</li>
            </ul>
            <br>
            <span class="cursive">
             For example, if you add a Spanish keyword, it will be tracked in Spain, Mexico, Argentina, Chile, and other Spanish-speaking countries.
            </span>
            <br>
            <span>
               ⚡ This mode saves you significant time by automatically tracking keywords across relevant countries.
            </span>
          </div>
        </div>
      </div>
      <div class="save-button">
        <base-button
            :disabled="worldwideTrackingModeEnable === userData.worldwide_tracking_mode_enable"
            width="199px"
            height="36px"
            class="btn-standard button-save"
            border-radius="4px"
            font-size="16px"
            @click="saveSettings">
          Save
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import {mapGetters} from "vuex";
import {httpRequest} from "@/api";
import {apiUrls} from "@/api/api";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";

export default {
  name: "Settings" ,
  components: {TableCheckbox, ProgressIndicator},
  data() {
    return {
      loaded: true,
      worldwideTrackingModeEnable: null,
    }
  },
  mounted() {
    this.worldwideTrackingModeEnable = this.userData.worldwide_tracking_mode_enable;
  },
  computed: {
    ...mapGetters([
      'userData',
    ]),
  },
  methods: {
    async saveSettings() {
      this.loaded = false;
      await httpRequest(
          'POST',
          process.env.VUE_APP_API_URL + apiUrls.user.SETTINGS,
          {
            'worldwide_tracking_mode_enable': this.worldwideTrackingModeEnable,
          }
      );
      this.userData.worldwide_tracking_mode_enable = this.worldwideTrackingModeEnable;
      this.loaded = true;
    }
  }
}
</script>

<style lang="scss" scoped src="./styles.scss"></style>
<style>
.custom-table-checkbox{
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #5D66FE;
  margin-right: 3px !important;
}
.custom-table-checkbox::before {
  opacity: 0;
  border: 1px solid #5D66FE;
}
</style>